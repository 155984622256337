import {KeycloakClient, Oath2Client} from 'components/auth';
import {AppRawClaims} from './claims.interface';
import {UserManagerSettings} from "oidc-client-ts";

const response = await fetch("/config.json");
const conf = await response.json();

const keycloakConfig = resolveKeycloakConfig();
const oath2Settings = resolveOath2Settings();

export default !keycloakConfig || conf.production
    ? new Oath2Client<AppRawClaims>(oath2Settings)
    : new KeycloakClient<AppRawClaims>(keycloakConfig, {
        onLoad: 'login-required',
        enableLogging: true,
        useNonce: false,
        checkLoginIframe: false,
    });

function resolveKeycloakConfig() {
    const {keycloak} = conf;

    if (!keycloak.url || !keycloak.realm || !keycloak.clientId) {
        return null;
    }
    return keycloak;
}

function resolveOath2Settings(): UserManagerSettings {
    const {oath2} = conf;
    return {
        ...oath2,
        response_type: 'code',
        scope: 'openid'
    }
}
