import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { useId } from 'react';
import { WithLabel } from 'shared/ui/WithLabel';

interface BaseAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  renderInput?: AutocompleteProps<
    T | string,
    Multiple,
    DisableClearable,
    FreeSolo
  >['renderInput'];
  label: string;
  error?: string;
}

function BaseAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  label,
  value,
  ...props
}: BaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const id = useId();

  return (
    <Autocomplete
      fullWidth
      size={'small'}
      value={value}
      {...props}
      renderInput={(params) => (
        <WithLabel label={label} id={id} errorText={props.error}>
          <TextField
            error={!!props.error}
            {...params}
            fullWidth
            placeholder={props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        </WithLabel>
      )}
    />
  );
}

export default BaseAutocomplete;
