import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    currency: React.CSSProperties;
    body1?: React.CSSProperties;
    body2: React.CSSProperties;
    percent: React.CSSProperties;
    caption: React.CSSProperties;
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    linkLarge: React.CSSProperties;
    linkMedium: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    currency: React.CSSProperties;
    body1?: React.CSSProperties;
    body2: React.CSSProperties;
    percent: React.CSSProperties;
    caption: React.CSSProperties;
    h1: React.CSSProperties;
    h2: React.CSSProperties;
    h3: React.CSSProperties;
    h4: React.CSSProperties;
    linkLarge: React.CSSProperties;
    linkMedium: React.CSSProperties;
    button1: React.CSSProperties;
    button2: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    currency: true;
    body1?: true;
    body2: true;
    percent: true;
    caption: true;
    h1: true;
    h2: true;
    h3: true;
    h4: true;
    linkLarge: true;
    linkMedium: true;
    button1: true;
    button2: true;
  }
}

const BODY_1 = {
  fontSize: '16px',
  lineHeight: '22px',
  letterSpacing: '-0.32px',
};

const BODY_2 = {
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.28px',
};

const LINK_LARGE = {
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: 600,
  letterSpacing: '-0.32px',
};

const BUTTON_MEDIUM = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
  letterSpacing: '-0.28px',
};

const LINK_MEDIUM = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  textDecorationLine: 'underline',
  letterSpacing: '-0.28px',
};

const H1 = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '50px',
  letterSpacing: '-0.8px',
};

const H2 = {
  fontSize: '32px',
  fontWeight: 700,
  lineHeight: '42px',
  letterSpacing: '-0.64px',
};

const H3 = {
  fontSize: '26px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.52px',
};

const H4 = {
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '28px',
  letterSpacing: '-0.4px',
};

const CAPTION = {
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '-0.24px',
};

const CURRENCY = {
  fontSize: '10px',
  lineHeight: '10px',
};

const PERCENT = {
  fontSize: '8px',
  lineHeight: 'normal',
};

export const typography = {
  body1: BODY_1,
  body2: BODY_2,
  currency: CURRENCY,
  percent: PERCENT,
  caption: CAPTION,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  linkMedium: LINK_MEDIUM,
  linkLarge: LINK_LARGE,
  button1: LINK_LARGE,
  button2: BUTTON_MEDIUM,
};
