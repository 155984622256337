import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import MenuItem from '../menu-item/MenuItem';
import CollapsedMenuItem from '../menu-item/CollapseMenuItem';
import snipped from 'assets/icons/snipped.svg';
import admin from 'assets/icons/admin.svg';
import book from 'assets/icons/book.svg';
import credit from 'assets/icons/credit.svg';
import emptyDocument from 'assets/icons/empty-document.svg';
import { PATHS } from 'core/routing/paths/appPaths';
import { useFeatures } from 'core/models/settings/queries';

export default function Menu() {
  const { data: features } = useFeatures({
    retry: 1,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const show = (name: string) => {
    const feature = features?.find((feature) => feature.name === name);
    return !!feature?.isActive;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        color: '#647081',
      }}
    >
      <MenuItem
        label={'Задачи'}
        path={'/tasks'}
        component={<FormatListBulletedOutlinedIcon color='inherit' />}
        canOptions={[{ I: 'read', a: 'task' }]}
      />
      <MenuItem
        label={'Кредитные решения'}
        path={PATHS.CREDIT_SOLUTIONS}
        img={credit}
        canOptions={[
          { I: 'read', a: 'kr' },
          { I: 'write', a: 'kr' },
          { I: 'update', a: 'kr' },
        ]}
      />
      <CollapsedMenuItem
        label={'Договоры'}
        img={snipped}
        options={[
          {
            label: 'Кредитные сделки',
            path: PATHS.CONTRACTS_TOP,
            canOptions: [
              { I: 'read', a: 'dvu' },
              { I: 'write', a: 'dvu' },
              { I: 'update', a: 'dvu' },
            ],
          },
          {
            label: 'Транш / Кредит',
            path: PATHS.CONTRACTS_LOW,
            canOptions: [
              { I: 'read', a: 'dnu' },
              { I: 'write', a: 'dnu' },
              { I: 'update', a: 'dnu' },
            ],
          },
        ]}
        canOptions={[
          { I: 'read', a: 'dvu' },
          { I: 'write', a: 'dvu' },
          { I: 'update', a: 'dvu' },
          { I: 'read', a: 'dnu' },
          { I: 'write', a: 'dnu' },
          { I: 'update', a: 'dnu' },
        ]}
      />
      {show('doc_contract_list_view') && (
        <MenuItem
          label={'Договоры(тест)'}
          path={'/doc-contracts'}
          img={snipped}
          canOptions={[
            { I: 'read', a: 'dvu' },
            { I: 'write', a: 'dvu' },
            { I: 'update', a: 'dvu' },
          ]}
        />
      )}
      {show('deals_list_view') && (
        <MenuItem
          label={'Сделки(тест)'}
          path={'/deal-contracts'}
          img={emptyDocument}
          canOptions={[
            { I: 'read', a: 'dnu' },
            { I: 'write', a: 'dnu' },
            { I: 'update', a: 'dnu' },
          ]}
        />
      )}

      <CollapsedMenuItem
        label={'Справочники'}
        img={book}
        options={[
          {
            label: 'Клиенты',
            path: PATHS.CUSTOMERS,
            canOptions: [
              { I: 'read', a: 'dvu' },
              { I: 'write', a: 'dvu' },
              { I: 'update', a: 'dvu' },
            ],
          },
          {
            label: 'Продукты',
            path: PATHS.PRODUCTS,
          },
          {
            label: 'Валюты',
            path: PATHS.CURRENCIES,
          },
        ]}
        canOptions={[
          { I: 'read', a: 'dictionary' },
          { I: 'write', a: 'dictionary' },
          {
            I: 'update',
            a: 'dictionary',
          },
        ]}
      />
      <CollapsedMenuItem
        label={'Администрирование'}
        options={[
          {
            label: 'Настройка ролей',
            path: PATHS.ROLES,
            canOptions: [
              { I: 'read', a: 'roles' },
              { I: 'write', a: 'roles' },
              { I: 'update', a: 'roles' },
            ],
          },
          {
            label: 'Конфигурационные параметры',
            path: PATHS.SYSTEM,
            canOptions: [
              { I: 'read', a: 'system' },
              { I: 'write', a: 'system' },
              { I: 'update', a: 'system' },
            ],
          },
        ]}
        img={admin}
        canOptions={[
          { I: 'read', a: 'system' },
          { I: 'write', a: 'system' },
          { I: 'update', a: 'system' },
        ]}
      />
    </div>
  );
}
