import { ReactNode, Suspense, useEffect } from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import { useAuthContext } from 'core/auth';
import { PATHS } from 'core/routing/paths/appPaths';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizedSuspender } from 'core/routing/Routing';

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const nav = useNavigate();
  const location = useLocation();
  const { isAuthenticated, claims } = useAuthContext();

  useEffect(() => {
    if (location.pathname.includes(PATHS.FORBIDDEN)) return;
    if (!isAuthenticated) return;
    if (!claims) return;
    if (claims.permissions.length === 0) {
      nav(PATHS.FORBIDDEN);
    }
    //remove query params from roles
    nav(location.pathname);
    // eslint-disable-next-line
  }, [isAuthenticated, claims]);

  return (
    <div
      className='layout'
      style={{
        height: '100vh',
        backgroundColor: '#F3F4F6',
        display: 'flex',
        padding: '24px',
      }}
    >
      <Sidebar />
      <Suspense fallback={LocalizedSuspender()}>{children}</Suspense>
    </div>
  );
}
