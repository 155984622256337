import Path from '../../utils/Path';

const api = Path.preset({ basename: '' });
export const rolesList = api('/limm-control/security/roles');

export const groupedRoleActions = (roleId: string) =>
  api(`/limm-control/security/roles/${roleId}/action`);

export const updateRoleActions = (roleId: string) =>
  api(`/limm-control/security/roles/${roleId}/actions`);

export const settings = api(`/limm-control/settings`);

export const settingsOptionsSearch = () => api(`/limm-control/settings/search`);

export const getSettingByCode = (code: string) =>
  api(`/limm-control/settings/${code}/`);
