import { useFeaturesContext } from '../featureFlagsContext/featureFlagsContext';
import { ReactComponent as CloseIcon } from './closeIcon.svg';
import styles from './FeatureFlagsWidget.module.scss';
import { useLocalStorage } from 'react-use';
import { createPortal } from 'react-dom';
import * as React from 'react';
import {
  ModalProperties,
  OpenFeatureFlagsModalButtonProperties,
} from './FeatureFlagsWidget.interface';

const LOCAL_STORAGE_FEATURES_ACTIVATION_KEY = 'feature-flags-activation';

function Modal({ closeModal, children }: ModalProperties) {
  const { initialFeatures, setFeatures } = useFeaturesContext();

  return (
    <div className={styles.modalContainer}>
      <div className={styles.popover} onClick={closeModal} />

      <div className={styles.modal}>
        <div className={styles.modalContentContainer}>{children}</div>
        <div className={styles.footer}>
          <button
            onClick={() => setFeatures(initialFeatures)}
            className={styles.resetButton}
          >
            Сбросить значения до дефолтных
          </button>
        </div>
        <button className={styles.closeModalButton} onClick={closeModal}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

function OpenFeatureFlagsModalButton({
  onClick,
}: OpenFeatureFlagsModalButtonProperties) {
  return (
    <button onClick={onClick} className={styles.openModalButton}>
      Open feature flags
    </button>
  );
}

export function FeatureFlagsModalContent() {
  const { features, setFeatures } = useFeaturesContext();

  function onCheckboxChange(featureKey: string) {
    // Почему используется объект features, а не коллбэк функция
    // https://github.com/streamich/react-use/issues/2316
    const newFeatures = { ...features };

    newFeatures[featureKey] = { ...newFeatures[featureKey] };
    newFeatures[featureKey].enabled = !newFeatures[featureKey].enabled;

    setFeatures(newFeatures);
  }

  const featuresElements = Object.entries(features).map(
    ([featureKey, feature]) => (
      <div className={styles.featuresContainer} key={featureKey}>
        <div>
          <b>Название фичи:</b> {featureKey}
        </div>

        <div>
          <b>Описание фичи:</b> {feature.description}
        </div>

        <div>
          <b>Ответственный:</b> {feature.responsible}
        </div>

        <label className={styles.checkboxLabel}>
          <b>Включить/Выключить: </b>
          <input
            onChange={() => onCheckboxChange(featureKey)}
            checked={feature.enabled}
            type='checkbox'
          />
        </label>
      </div>
    )
  );

  return <div>{featuresElements}</div>;
}

export function FeatureFlagsWidget() {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [featureFlagsActivationValue] = useLocalStorage(
    LOCAL_STORAGE_FEATURES_ACTIVATION_KEY
  );

  function closeModal() {
    setModalOpen(false);
  }

  function openModal() {
    setModalOpen(true);
  }

  if (!featureFlagsActivationValue) {
    return null;
  }

  if (!modalOpen) {
    return <OpenFeatureFlagsModalButton onClick={openModal} />;
  }

  return createPortal(
    <Modal closeModal={closeModal}>
      <FeatureFlagsModalContent />
    </Modal>,
    document.querySelector('body') as HTMLBodyElement
  );
}

export function enableFeatureFlagsWidget() {
  localStorage.setItem(LOCAL_STORAGE_FEATURES_ACTIVATION_KEY, 'true');
  window.location.reload();
}
