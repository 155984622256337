import { PaletteColor } from '@mui/material';

interface SecondaryColors {
  gradient?: string;
  hard?: string;
  medium?: string;
  light?: string;
  hover?: string;
}

interface SuccessColors {
  gradient?: string;
  hard: '#34B450';
  medium: '#A4E9B3';
  light: '#DCFFE4';
}

interface Tech {
  gradient?: string;
  hard?: string;
  medium?: string;
  light?: string;
  border?: string;
  white?: string;
  toast?: string;
  black?: string;
  blackBlue?: string;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: PaletteColor;
    gray: PaletteColor;
    white: PaletteColor;
    border: PaletteColor;
    tech: Tech;
    green: SuccessColors;
    yellow: SecondaryColors;
    red: SecondaryColors & { hover: string };
    blue: SecondaryColors;
    primaryBlue: string;
    primaryRed: string;

    [index: string]: PaletteColor;
  }

  interface PaletteOptions {
    black?: PaletteColorOptions;
    gray?: PaletteColorOptions;
    white?: PaletteColorOptions;
    border?: PaletteColorOptions;
    tech?: Tech;
    primaryBlue: string;
    primaryRed: string;
  }
}

// main
const TECH = {
  white: '#FFFFFF',
  border: '#F3F4F6',
  light: '#E4E7EB',
  medium: '#A8B1BE',
  hard: '#647081',
  toast: '#27303A',
  black: '#0A0A0A',
  blackBlue: '#000026',
};

const PRIMARY = {
  light: '#FCE0D9',
  main: '#E40038',
  dark: '#E40038',
  contrastText: '#FFAAAF',
};

const SECONDARY = {
  main: '#00234D',
};

const BLUE = {
  hard: '#39A9FF',
  medium: '#AAE1FF',
  light: '#E7F4FD',
};

const DARK_BLUE = '#00234D';

const RED = {
  hard: '#E40038',
  medium: '#FFAAAF',
  light: '#FCE0D9',
  hover: '#F13544',
};

const GREEN: SuccessColors = {
  hard: '#34B450',
  medium: '#A4E9B3',
  light: '#DCFFE4',
  gradient: 'linear-gradient(90deg, #73E49A 0%, #32C665 98.96%)',
};

const YELLOW = {
  hard: '#FFAF00',
  medium: '#FFCC81',
  light: '#FFECD0',
};

const ERROR: PaletteColor = {
  dark: '#E40038',
  main: '#FFAAAF',
  light: '#FFF5F3',
  contrastText: '#E40038',
};

const SUCCESS: PaletteColor = {
  dark: '#34B450',
  main: '#A4E9B3',
  light: '#F6FFF9',
  contrastText: '#34B450',
};

const INFO: PaletteColor = {
  dark: '#39A9FF',
  main: '#AAE1FF',
  light: '#F5F9FF',
  contrastText: '#00234D',
};

const WARNING: PaletteColor = {
  dark: '#FFAF00',
  main: '#FFCC81',
  light: '#FFECD0',
  contrastText: '#FFAF00',
};

export const palette = {
  // main
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  tech: { ...TECH },
  red: { ...RED },
  green: { ...GREEN },
  yellow: { ...YELLOW },
  blue: { ...BLUE },
  primaryBlue: DARK_BLUE,
  primaryRed: RED.hard,
  error: ERROR,
  success: SUCCESS,
  warning: WARNING,
  info: INFO,
};
