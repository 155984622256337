import { createContext, ReactNode } from 'react';
import { Ability } from '@casl/ability';
import createCanHooks from './createCanHooks';
import createCanComponent from './createCanComponent';

/** Фабрика используется главным образом для использования дженерика
 * ActualAbility. Кроме того это позволяет не передавать хук useAbility через
 * пропсы провайдера (это антипаттерн, потому что хук нельзя менять "на лету",
 * а передача через пропсы предполагает такое поведение)
 */
export default function createAbilityContext<ActualAbility extends Ability>({
  useAbility,
  defaultAbility,
}: {
  useAbility: () => ActualAbility;
  defaultAbility: ActualAbility;
}) {
  const AbilityContext = createContext<ActualAbility>(defaultAbility);

  const { useCan, useCannot, useCanOr } = createCanHooks<ActualAbility>({
    AbilityContext,
  });
  const Can = createCanComponent<ActualAbility>({ AbilityContext });

  return {
    AbilityProvider,
    Can,
    useCan,
    useCannot,
    useCanOr
  };

  function AbilityProvider({ children }: { children: ReactNode }) {
    const ability = useAbility();
    return (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    );
  }
}
