import { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import {useCanOr} from "core/auth";
import {AnyCanProps} from "components/auth/AbilityContext/AbilityContext.interface";


export default function MenuItem({
  label,
  img,
  path,
  component,
  canOptions
}: {
  label: string;
  img?: string;
  path: string;
  component?: ReactNode; canOptions: AnyCanProps[]
}) {
    const can = useCanOr(canOptions);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const match = pathname.startsWith(path);
  const handleOnClick = () => {
    navigate(path);
  };

  return can ? (
    <Grid
      sx={{
        display: 'flex',
        gap: '12px',
        padding: '12px',
        alignItems: 'center',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: 'white',
        },
      }}
      onClick={handleOnClick}
    >
      {img ? (
        <img
          src={img}
          color='inherit'
          style={{ width: '24px', height: '24px' }}
          alt={''}
        />
      ) : (
        component
      )}
      <Typography
        variant={'button2'}
        color={match ? 'primaryRed' : 'tech.hard'}
      >
        {label}
      </Typography>
    </Grid>
  ) : null;
}
