import { createAuthContext } from 'components/auth';
import claimsAdapter from './claimsAdapter';
import { AppRawClaims, AppClaims } from './claims.interface';

const { AuthProvider, withAuthContext, useAuthContext } = createAuthContext<
  AppRawClaims,
  AppClaims
>({ claimsAdapter, defaultClaims: null });

export { AuthProvider, withAuthContext, useAuthContext };
