import { AppClaims, AppRawClaims } from './claims.interface';
import { Roles } from './roles';

export default function claimsAdapter(claims: AppRawClaims): AppClaims {
  if (!claims) {
    return null;
  }
  const { permissions, username } = claims;
  return { admin: true, roles: [Roles.User, Roles.Admin], permissions, username};
}
