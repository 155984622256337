import { defineAbility } from '@casl/ability';
import { AppClaims } from '../authentication/claims.interface';
import {ABILITY_CONFIG, AppAbility} from './abilities.interface';

export default function claimsToAbility(claims: AppClaims): AppAbility {
  const { permissions = [] } = claims ?? {};
  return defineAbility<AppAbility>((allow) => {
    ABILITY_CONFIG.forEach(option => {
      const { claimName, subject, action } = option;
      if (permissions.includes(claimName)) {
        allow(action, subject)
      }
    });
  });
}
