import createTheme from '@mui/material/styles/createTheme';
import { palette } from 'app/themes/palette';
import { typography } from 'app/themes/typography';
import { alertStyles } from './alert';
import { buttonStyles } from './buttons';
import { outlinedInput, standardInput } from './inputs';

export const theme = createTheme({
  palette: palette,
  typography: { allVariants: { fontFamily: 'Montserrat' }, ...typography },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '28px',
          height: '16px',
          padding: '0',
          '.MuiSwitch-switchBase': {
            padding: 0,
            '&.Mui-disabled': {
              '&+.MuiSwitch-track': {
                opacity: 1,
                backgroundColor: palette.tech.white,
                border: `1px solid ${palette.tech.medium}`,
              },
              '.MuiSwitch-thumb': {
                opacity: 1,
                backgroundColor: palette.tech.white,
                border: '1px solid #CECECE',
              },
            },
          },
          '.MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(12px)',
            '&+.MuiSwitch-track': {
              opacity: 1,
            },
          },
          '.MuiSwitch-thumb': {
            backgroundColor: 'white',
            width: '12px',
            height: '12px',
            margin: '2px',
          },
          '.MuiSwitch-track': {
            borderRadius: '8px',
            backgroundColor: palette.tech.medium,
            opacity: 1,
          },
        },
      },
    },
    MuiOutlinedInput: outlinedInput,
    MuiInput: standardInput,
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: '#F3F4F6',
          color: '#000C1A',
          border: '2px solid #EDEDED',
          '&.Mui-selected:hover': {
            border: '2px solid #E40038',
            backgroundColor: '#FCE0D9',
            color: '#E40038',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          marginTop: '0px !important',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.red.hover,
          },
          '&.Mui-checked': {
            color: theme.palette.red.hard,
          },
        }),
      },
    },
    MuiButton: buttonStyles,
    MuiAlert: alertStyles,
  },
  spacing: 1,
});
