import { QueryClient, QueryClientProvider } from 'react-query';
import { AbilityProvider, authClient, AuthProvider } from 'core/auth';
import ErrorBoundary from 'core/components/ErrorBoundary/ErrorBoundary';
import {
  FeatureFlagsWidget,
  FeatureFlagsProvider,
} from 'components/featureFlags';
import Routing from 'core/routing/Routing';
import { features } from 'core/features';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'app/themes/theme';
import 'react-toastify/dist/ReactToastify.css';

import { Toastify } from 'shared/ui/NotifyMessage/StyledNotifyContainer';

import { ErrorModalProvider } from './errorModalProvider/ErrorModalProvider';

const queryClient = new QueryClient();

const App = () => {
  return (
    <ErrorBoundary>
      <FeatureFlagsProvider initialFeatures={features}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider client={authClient}>
            <ThemeProvider theme={theme}>
              <AbilityProvider>
                <ErrorModalProvider>
                  <FeatureFlagsWidget />
                  <Routing />
                  <Toastify />
                </ErrorModalProvider>
              </AbilityProvider>
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </FeatureFlagsProvider>
    </ErrorBoundary>
  );
};

export default App;
