import axios from 'axios';
import { v4 as uuid } from 'uuid';
import {authClient} from 'core/auth';

const axiosInstance = axios.create({
    // baseURL: '/limm-control',
    // withCredentials: false,
});

axiosInstance.interceptors.request.use(
    async function onFulfilled(config) {
        // @ts-ignore
        config.headers.common['requestId'] = uuid();
        const authenticated = await authClient.ensureAuthentication(config);
        if (!authenticated) {
            throw new axios.Cancel(
                'User is not authenticated, and session restoration is not possible'
            );
        }
        return config;
    },
    function onRejected(error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async function onFulfilled(response) {
        return (await response)?.data;
    },
    async function onRejected(error) {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest?._retry) {
            originalRequest._retry = true;
            if (await authClient.restoreAuthentication()) {
                return axiosInstance(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
