import { IconButton, styled } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { theme } from 'app/themes/theme';
import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from 'assets/icons';
import React from 'react';

const CloseButton = ({ closeToast }: any) => (
  <IconButton className='Close-button' onClick={closeToast}>
    <CloseIcon />
  </IconButton>
);

const StyledToastContainer = styled(ToastContainer)`
  width: auto;
  min-width: 320px;
  max-width: 800px;

  .Toastify__toast-theme--light.Toastify__toast--success {
    background: ${theme.palette.success.light};
    box-shadow: 0 6px 12px 0 rgba(0, 176, 7, 0.2);
    border: 1px solid ${theme.palette.success.main};
  }

  .Toastify__toast-theme--light.Toastify__toast--error {
    background: ${theme.palette.error.light};
    box-shadow: 0 6px 12px 0 rgba(228, 0, 56, 0.2);
    border: 1px solid ${theme.palette.error.main};
  }

  .Toastify__toast-theme--light.Toastify__toast--warning {
    background: ${theme.palette.warning.light};
    box-shadow: 0 6px 12px 0 rgba(255, 175, 0, 0.2);
    border: 1px solid ${theme.palette.warning.main};
  }

  .Toastify__toast-theme--light.Toastify__toast--info {
    background: ${theme.palette.info.light};
    box-shadow: 0 6px 12px 0 rgba(57, 169, 255, 0.2);
    border: 1px solid ${theme.palette.info.main};
  }

  .Toastify__toast {
    padding: 8px 20px;
    min-width: 320px;
    min-height: 64px;
    width: fit-content;
    margin-left: auto;
    gap: 16px;
  }

  .Toastify__toast-body {
    margin-inline-end: 16px;
    align-items: flex-start;
    padding: 0;
    margin: 0;
  }

  .Toastify__toast-body > div:last-child {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .Toastify__toast-icon {
    margin-top: 10px;
    margin-inline-end: 16px;
    width: 24px;
    flex-shrink: 0;
    display: flex;
  }

  .Close-button {
    align-items: flex-start;
    margin-top: 12px;
    height: 18px;
    padding: 0;
  }
`;

export const Toastify = () => (
  <StyledToastContainer
    icon={({ type }) => {
      const iconsMap = {
        warning: <WarningIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        success: <SuccessIcon />,
        default: <InfoIcon />,
      };
      return iconsMap[type];
    }}
    closeButton={CloseButton}
    position={'top-right'}
    hideProgressBar
  />
);
