import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const outlinedInput: {
  defaultProps?: ComponentsProps['MuiOutlinedInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput'];
  variants?: ComponentsVariants['MuiOutlinedInput'];
} = {
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => {
      return {
        backgroundColor: palette.tech.white,
        color: '#0A0A0A',
        'input::placeholder': {
          opacity: 1,
          color: palette.tech.hard,
          ...typography.body2,
        },
        '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
          border: `1px solid var(--primary-blue, ${palette.primaryBlue})`,
        },
      };
    },
  },
};

export const standardInput: {
  defaultProps?: ComponentsProps['MuiInput'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiInput'];
  variants?: ComponentsVariants['MuiInput'];
} = {
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => {
      return {
        color: '#0A0A0A',
        'input::placeholder': {
          opacity: 1,
          color: palette.tech.hard,
          ...typography.body2,
        },

        '&:not(.Mui-disabled, .Mui-error):hover': {
          '::before': { borderBottom: `1px solid ${palette.tech.light}` },
        },

        '&::before': { borderBottom: `1px solid ${palette.tech.border}` },

        '&::after': {
          borderBottom: `1px solid ${palette.tech.medium}`,
          '&:hover': {
            borderBottom: `1px solid ${palette.tech.medium}`,
          },
        },
      };
    },
  },
};
