export interface SearchResult<Type> {
  totalSize: number;
  totalPages: number;
  data: Array<Type>;
}

export interface Sort {
  key?: string;
  direction?: SortDirection;
}

export interface SearchRequest {
  offset: number;
  limit: number;
  filters: Array<SearchRequestFilter>;
  sort: Array<Sort>;
}

export interface SearchRequestFilter {
  '@type': string; //"Тип применяемого фильтра, см. список поддерживаемых фильтров ниже", обязательное поле
  key: string; // "Наименование поля, по которому осуществляется фильтрация", обязательное поле
  match?:
    | string
    | SearchRequestFilter[]
    | number
    | null
    | SearchRequestFilter
    | boolean; // "значение поля по которому осуществляется фильтрация", не обязательное поле
  from?: string; // "нижнее значение диапазона по которому осуществляется фильтрация", обязательное поле
  to?: string; // "верхнее значение диапазона по которому осуществляется фильтрация", не обязательное поле
  join?: string; // "не обязательное поле, применяется в случае, если надо осуществить поиск в связной сущности
}

export enum SearchRequestFilterType {
  eq = 'eq', // Равенство(=)
  regexp = 'regexp', // Совпадение по регулярному выражению
  cieq = 'cieq', // Равенство (=) без учета  регистра
  ct = 'ct', // Вхождение
  cict = 'cict', // Вхождение без учета регистра
  ne = 'ne', // Не равенство (<>)
  gt = 'gt', // Больше(>)
  gte = 'gte', // Больше или равно (>=)
  lt = 'lt', // Меньше(<)
  lte = 'lte', // Меньше или равно(<=)
  not = 'not', // Отрицание (!)
  and = 'and', // И(&)
  or = 'or', // ИЛИ (|)
  date = 'date', // Поиск дат, поля from to
  isNull = 'isNull', // null
  exists = 'exists',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
