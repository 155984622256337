import { Context, ReactNode, useEffect, useMemo, useState } from 'react';
import AbstractAuthClient from './clients/AbstractAuthClient/AbstractAuthClient.interface';
import { AuthContextValue, ClaimsAdapter } from './AuthContext.interface';

export * from './AuthContext.interface';

export default function createAuthProvider<RawClaims, Claims>({
  AuthContext,
  claimsAdapter,
  defaultClaims,
}: {
  AuthContext: Context<AuthContextValue<Claims>>;
  claimsAdapter: ClaimsAdapter<RawClaims, Claims>;
  defaultClaims: Claims;
}) {
  return function AuthProvider({
    client,
    children,
  }: {
    client: AbstractAuthClient<RawClaims>;
    children: ReactNode;
  }) {
    const [claims, setClaims] = useState<Claims>(defaultClaims);
    const isAuthenticated = Boolean(claims);
    const authContext = useMemo<AuthContextValue<Claims>>(
      () => ({ isAuthenticated, claims }),
      [isAuthenticated, claims]
    );

    useEffect(
      () => {
        client.init({
          async onClaimsUpdate(nextRawClaims: RawClaims) {
            setClaims(await claimsAdapter(nextRawClaims));
          },
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
      /* Массив deps пустой, т.к. клиент авторизации не может быть повторно
      инициализирован. И эффект работает по принципу componentDidMount */
    );

    return (
      <AuthContext.Provider value={authContext}>
        {children}
      </AuthContext.Provider>
    );
  };
}
