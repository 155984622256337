import { ReactNode } from 'react';
import { useConfirm } from 'shared/hooks';

import {
  ErrorModalContent,
  ErrorModalContext,
} from 'shared/ui/Modal/ConfirmModalContent';

export const ErrorModalProvider = ({ children }: { children: ReactNode }) => {
  const { getConfirmation, Confirmation } = useConfirm(ErrorModalContent);

  return (
    <ErrorModalContext.Provider value={getConfirmation}>
      {children}
      <Confirmation />
    </ErrorModalContext.Provider>
  );
};
