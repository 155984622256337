import {Action, Subject} from "core/auth/authorization/abilities.interface";
import {useCanOr} from "core/auth";
import {Navigate} from "react-router-dom";
import {PATHS} from "../appPaths";

interface ProtectedProps {
    children: any,
    subject: Subject,
    actions: Action[]
}

export default function Protected({children, subject, actions}: ProtectedProps) {
    const allow = useCanOr(actions.map(a => {
        return {I: a, a: subject};
    }));

    if (allow) {
        return <>{children}</>;
    }
    return <Navigate to={PATHS.FORBIDDEN} />

}