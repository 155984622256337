import {Context, useContext} from 'react';
import {AnyAbility} from '@casl/ability';
import {AnyCanProps} from './AbilityContext.interface';
import {extractActionAndSubject} from './utils';

export default function createCanHooks<AbilityContextValue extends AnyAbility>({
                                                                                   AbilityContext,
                                                                               }: {
    AbilityContext: Context<AbilityContextValue>;
}) {
    return {useCan, useCannot, useCanOr};

    function useCan(props: Omit<AnyCanProps, 'not'>) {
        return useCanBase({...props, not: false});
    }

    function useCannot(props: Omit<AnyCanProps, 'not'>) {
        return useCanBase({...props, not: true});
    }

    function useCanBase(props: AnyCanProps) {
        const ability = useContext(AbilityContext);
        return check(props, ability);
    }

    function useCanOr(props: AnyCanProps[]) {
        const ability = useContext(AbilityContext);
        return props.map(p => {
            return check(p, ability);
        }).reduce(
            (previousValue, currentValue) => previousValue || currentValue,
            false
        );

    }

    function check({as, not, ...actionAndSubjectProps}: AnyCanProps, ability: AbilityContextValue) {
        const check = not ? 'cannot' : 'can';
        const {action, subjectValue} = extractActionAndSubject(
            actionAndSubjectProps
        );

        if (!action || !subjectValue) {
            // true для useCannot и false для useCan.
            // Т.е., если не удается определить правило, то запрещаем его
            return not;
        }

        return ability[check](action, subjectValue);
    }

}
