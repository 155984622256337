import { useMemo, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useCanOr } from 'core/auth';
import { AnyCanProps } from 'components/auth/AbilityContext/AbilityContext.interface';

function Item({
  path,
  label,
  handleOnClick,
  canOptions = [],
}: {
  path: string;
  label: string;
  handleOnClick: any;
  canOptions?: AnyCanProps[];
}) {
  const can = useCanOr(canOptions);
  const show = canOptions.length !== 0 ? can : true;
  const location = useLocation();

  return show ? (
    <Typography
      key={label}
      variant={'button2'}
      onClick={handleOnClick}
      color={
        path && location.pathname.startsWith(path) ? 'primaryRed' : 'tech.hard'
      }
      sx={{
        padding: '14px',
        borderLeft: '1px solid #E4E7EB',
        cursor: 'pointer',
      }}
    >
      {label}
    </Typography>
  ) : null;
}

export default function CollapsedMenuItem({
  label,
  img,
  options,
  canOptions = [],
}: {
  label: string;
  img: string;
  options: Array<{ path: string; label: string; canOptions?: AnyCanProps[] }>;
  canOptions?: AnyCanProps[];
}) {
  const can = useCanOr(canOptions);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const isMatched = useMemo(
    () =>
      options.some(({ path }) => path && location.pathname.startsWith(path)),
    [options, location.pathname]
  );

  const handleIconClicked = () => {
    setShow((prev) => !prev);
  };

  const navigate = useNavigate();
  const handleOnClick = (path: string) => {
    navigate(path);
  };

  return can ? (
    <>
      <Grid
        display={'flex'}
        p={'12px'}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderRadius={'4px'}
        sx={{
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'white',
          },
        }}
        onClick={handleIconClicked}
      >
        <div
          style={{
            display: 'flex',
            gap: '12px',
            alignItems: 'center',
          }}
        >
          <img
            src={img}
            color='inherit'
            style={{ width: '24px', height: '24px' }}
            alt={''}
          />
          <Typography
            variant={'button2'}
            color={isMatched ? 'primaryRed' : 'tech.hard'}
          >
            {label}
          </Typography>
        </div>
        {show ? (
          <KeyboardArrowUpIcon
            color='inherit'
            style={{ width: '24px', height: '24px', color: '#647081' }}
          />
        ) : (
          <KeyboardArrowDownIcon
            color='inherit'
            style={{ width: '24px', height: '24px', color: '#647081' }}
          />
        )}
      </Grid>
      <Collapse in={show}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px 0 0 24px',
          }}
        >
          {options.map(({ label, path, canOptions }) => (
            <Item
              key={label}
              path={path}
              label={label}
              handleOnClick={() => handleOnClick(path)}
              canOptions={canOptions}
            />
          ))}
        </div>
      </Collapse>
    </>
  ) : null;
}
