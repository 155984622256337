import { SuspenderProps } from './Suspender.interface';

export type { SuspenderProps };

export default function Suspender({ className, message }: SuspenderProps) {
  return (
    <div className={className}>
      {message}
      <progress className='progress is-small is-primary' max='100' />
    </div>
  );
}
