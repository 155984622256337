import { createContext, useContext, ComponentType } from 'react';
import { AuthContextValue, ClaimsAdapter } from './AuthContext.interface';
import createAuthProvider from './createAuthProvider';

/** Фабрика используется главным образом для использования дженериков
 * RawClaims и Claims. Кроме того это позволяет привязать claimsAdapter и
 * defaultClaims на уровне фабрики, а не пропсов провайдера. Что в данном
 * случае, субъективно, более элегантное решение
 */
export default function createAuthContext<RawClaims, Claims>({
  claimsAdapter,
  defaultClaims,
}: {
  claimsAdapter: ClaimsAdapter<RawClaims, Claims>;
  defaultClaims: Claims;
}) {
  const AuthContext = createContext<AuthContextValue<Claims>>({
    isAuthenticated: false,
    claims: defaultClaims
  });

  return {
    AuthProvider: createAuthProvider({
      AuthContext,
      claimsAdapter,
      defaultClaims,
    }),
    useAuthContext,
    withAuthContext,
  };

  function useAuthContext() {
    return useContext(AuthContext);
  }

  function withAuthContext(Component: ComponentType) {
    return function ComponentWithAuthContext(props: any) {
      const authContext = useAuthContext();
      return <Component {...props} authContext={authContext} />;
    };
  }
}
