import { lazy } from 'react';
import AppPath from './utils/AppPath';

export const PATHS = {
  CREDIT_SOLUTIONS: '/creditSolutions',
  DOC_CONTRACTS: '/doc-contracts',
  CONTRACTS_TOP: '/contracts/top',
  CONTRACTS_LOW: '/contracts/low',
  TASKS: '/tasks',
  CUSTOMERS: '/dictionary/customers',
  PRODUCTS: '/dictionary/products',
  CURRENCIES: '/dictionary/currency',
  FORBIDDEN: '/forbidden',
  GROUPS: '/dictionary/customers/groups',
  ROLES: '/administration/roles',
  SYSTEM: '/administration/system',
  FEATURES: '/features',
};

/**
 * Пример использования:
 * export const myRoute = r('/my/:param/:optionalParam?');', {
 *   Component: About,
 *   props: { myProp: 'whatever' }
 * });
 */
const r /* r — значит route */ = AppPath.preset({
  // Лейаут по умолчанию, можно переопределить для конкретного роута
  Layout: lazy(
    () =>
      import(
        /* webpackChunkName: 'default' */ 'core/layout/DefaultLayout/DefaultLayout'
      )
  ),
});

export const routes = {
  home: r('/', {
    Component: lazy(
      () => import(/* webpackChunkName: 'default' */ 'pages/home/Home')
    ),
  }),
  creditSolutions: r(PATHS.CREDIT_SOLUTIONS, {
    Component: lazy(() => import('pages/CreditSolutions/CreditSolutions')),
    can: {
      subject: 'kr',
      actions: ['read'],
    },
  }),
  editCreditSolution: r(`${PATHS.CREDIT_SOLUTIONS}/edit/:id`, {
    Component: lazy(
      () =>
        import(
          'pages/CreditSolutions/createCreditSolution/CreateCreditSolution'
        )
    ),
    can: {
      subject: 'kr',
      actions: ['read', 'write'],
    },
  }),
  creditSolution: r(`${PATHS.CREDIT_SOLUTIONS}/:id`, {
    Component: lazy(
      () =>
        import('pages/CreditSolutions/creditSolutionItem/CreditSolutionItem')
    ),
    can: {
      subject: 'kr',
      actions: ['read'],
    },
  }),

  createCreditSolution: r(`${PATHS.CREDIT_SOLUTIONS}/create`, {
    Component: lazy(
      () =>
        import(
          'pages/CreditSolutions/createCreditSolution/CreateCreditSolution'
        )
    ),
    can: {
      subject: 'kr',
      actions: ['write'],
    },
  }),
  ceiling: r(`${PATHS.CREDIT_SOLUTIONS}/:id/ceiling/:ceilingId`, {
    Component: lazy(() => import('pages/ceiling/CeilingPage')),
    can: {
      subject: 'kr',
      actions: ['read'],
    },
  }),

  ceilingEdit: r(`${PATHS.CREDIT_SOLUTIONS}/:id/ceiling/:ceilingId/edit`, {
    Component: lazy(() => import('pages/ceiling/CeilingPage')),
    can: {
      subject: 'kr',
      actions: ['read'],
    },
  }),

  customerForm: r(`${PATHS.CUSTOMERS}/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/customers/customer/Customer'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  customerEditForm: r(`${PATHS.CUSTOMERS}/:id/edit`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/customers/create-customer/CreateCustomer'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['update'],
    },
  }),
  customersForm: r(PATHS.CUSTOMERS, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/customers/view-customers/Customers'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  createCustomersForm: r(`${PATHS.CUSTOMERS}/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/customers/create-customer/CreateCustomer'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['write'],
    },
  }),
  currencyForm: r(PATHS.CURRENCIES, {
    Component: lazy(
      () => import(/* webpackChunkName: 'default' */ 'pages/currency/Currency')
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  currencyCreateForm: r(`${PATHS.CURRENCIES}/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/currency/createCurrency/CreateCurrency'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['write'],
    },
  }),
  currencyRateCreateForm: r(`${PATHS.CURRENCIES}/rate/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/currency/createCurrencyRate/CreateCurrencyRate'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['write'],
    },
  }),
  productsForm: r(PATHS.PRODUCTS, {
    Component: lazy(
      () => import(/* webpackChunkName: 'default' */ 'pages/products/Products')
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  productEditForm: r(`${PATHS.PRODUCTS}/:id/edit`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/products/createProduct/CreateProduct'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['update'],
    },
  }),
  productForm: r(`${PATHS.PRODUCTS}/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/products/viewProduct/ViewProduct'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  createProductForm: r(`${PATHS.PRODUCTS}/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/products/createProduct/CreateProduct'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['write'],
    },
  }),
  groupForm: r(`${PATHS.GROUPS}/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'default' */ 'pages/group/viewGroup/ViewGroup'
        )
    ),
    can: {
      subject: 'dictionary',
      actions: ['read'],
    },
  }),
  tasksForm: r('/tasks', {
    Component: lazy(
      () => import(/* webpackChunkName: 'default' */ 'pages/tasks/Tasks/Tasks')
    ),
    can: {
      subject: 'task',
      actions: ['read'],
    },
  }),
  taskForm: r('/tasks/:id', {
    Component: lazy(
      () => import(/* webpackChunkName: 'default' */ 'pages/tasks/Task/Task')
    ),
    can: {
      subject: 'task',
      actions: ['read'],
    },
  }),
  agreementTop: r(`${PATHS.CONTRACTS_TOP}/`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/contractsTopList/ContractsTopList'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),
  agreementTopView: r(`${PATHS.CONTRACTS_TOP}/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/contractTopView/ContractTopView'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),

  agreementTopCreate: r(`${PATHS.CONTRACTS_TOP}/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/contractTopCreate/ContractTopCreate'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  pledgeContractCreate: r(`${PATHS.CONTRACTS_TOP}/:id/pledge/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/pledgeContract/edit/createPledge/createPledge'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  additionalContractTopCreate: r(
    `${PATHS.CONTRACTS_TOP}/:id/additional/create`,
    {
      Component: lazy(
        () =>
          import(
            /* webpackChunkName: 'bjn' */ 'pages/contractsTop/additionalContractCreate/AdditionalContractTopCreate'
          )
      ),
      can: {
        subject: 'dvu',
        actions: ['write'],
      },
    }
  ),

  additionalContractLowCreate: r(
    `${PATHS.CONTRACTS_LOW}/:id/additional/create`,
    {
      Component: lazy(
        () =>
          import(
            /* webpackChunkName: 'bjn' */ 'pages/contractsLow/additionalContractCreate/AdditionalContractLowCreate'
          )
      ),
      can: {
        subject: 'dnu',
        actions: ['write'],
      },
    }
  ),

  pledgeContractView: r(`${PATHS.CONTRACTS_TOP}/:topContractId/pledge/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/pledgeContract/view/pledgeContractView'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),

  agreementLow: r(`${PATHS.CONTRACTS_LOW}/`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsLow/contractsLowList/ContractsLowList'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  agreementLowView: r(`${PATHS.CONTRACTS_LOW}/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsLow/contractLowView/ContractLowView'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  contractLowCreate: r(`${PATHS.CONTRACTS_LOW}/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsLow/contractLowCreate/ContractLowCreate'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['write'],
    },
  }),

  relatedContractLowCreate: r(
    `${PATHS.CONTRACTS_TOP}/:topContractId/related/create`,
    {
      Component: lazy(
        () =>
          import(
            /* webpackChunkName: 'bjn' */ 'pages/contractsLow/contractLowCreate/ContractLowCreate'
          )
      ),
      can: {
        subject: 'dnu',
        actions: ['write'],
      },
    }
  ),
  contractLowDebtRepayment: r(`${PATHS.CONTRACTS_LOW}/:id/repayment/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsLow/contractLowDebt/ContractLowDebt'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['write'],
    },
  }),
  contractTopDebtRepayment: r(`${PATHS.CONTRACTS_TOP}/:id/repayment/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contractsTop/contractTopDebt/ContractTopDebt'
        )
    ),
  }),

  rolesAdministration: r(`${PATHS.ROLES}`, {
    Component: lazy(
      () =>
        import(/* webpackChunkName: 'bjn' */ 'pages/administration/roles/Roles')
    ),
  }),

  roleEdit: r(`${PATHS.ROLES}/edit/:name/:description`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/administration/roles/EditRole'
        )
    ),
  }),

  systemAdministration: r(`${PATHS.SYSTEM}`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/administration/system/SystemSettings'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  systemSettingsEdit: r(`${PATHS.SYSTEM}/edit`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/administration/system/SustemSettingsEdit/SystemSettingsEdit'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  contract: r(`doc-contracts/:id`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/contractView/ContractView'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),

  contractsList: r(`doc-contracts`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/contractsList/ContractsList'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),

  deal: r(`deal-contracts/:id`, {
    Component: lazy(
      () =>
        import(/* webpackChunkName: 'bjn' */ 'pages/deals/dealView/DealView')
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  dealsList: r(`deal-contracts`, {
    Component: lazy(
      () =>
        import(/* webpackChunkName: 'bjn' */ 'pages/deals/dealsList/DealsList')
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  dealCreate: r(`deal-contracts/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/deals/dealCreate/DealCreate'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['write'],
    },
  }),

  createContract: r(`doc-contracts/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/contractCreate/ContractCreate'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['write'],
    },
  }),

  pledge: r(`deal-contracts/:id/pledges/:pledgeId`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/pledges/view/pledgeContractView'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  additionalContract: r(`doc-contracts/:id/additional/:addId`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/additionalContractView/AdditionalContractView'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['read'],
    },
  }),

  additionalContractCreate: r(`doc-contracts/:id/additional/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/additionalContractCreate/AdditionalContractCreate'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  additionalDeal: r(`deal-contracts/:id/additional/:addId`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/deals/dealAdditionalContractView/dealAdditionalContractView'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['read'],
    },
  }),

  additionalDealCreate: r(`deal-contracts/:id/additional/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/deals/dealAdditionalContractCreate/DealAdditionalContractCreate'
        )
    ),
    can: {
      subject: 'dnu',
      actions: ['write'],
    },
  }),

  pledgeCreate: r(`deal-contracts/:id/pledges/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/pledges/view/pledgeContractView'
        )
    ),
    can: {
      subject: 'dvu',
      actions: ['write'],
    },
  }),

  addContractRepayment: r(`doc-contracts/:id/repayment/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/addRepayment/AddContractRepayment'
        )
    ),
  }),
  addDealRepayment: r(`deal-contracts/:id/repayment/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/deals/addRepayment/AddDealRepayment'
        )
    ),
  }),

  addContractPledge: r(`doc-contracts/:id/pledge/create`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/pledges/edit/createContractPledge/createContractPledge'
        )
    ),
  }),

  viewContractPledge: r(`doc-contracts/:id/pledge/:pledgeId`, {
    Component: lazy(
      () =>
        import(
          /* webpackChunkName: 'bjn' */ 'pages/contracts/pledges/view/pledgeContractView'
        )
    ),
  }),
};
