import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { AlertColor, Theme } from '@mui/material';
import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsVariants } from '@mui/material/styles/variants';
import { ReactComponent as Success } from 'assets/icons/success.svg';
import { ReactComponent as Info } from 'assets/icons/informative.svg';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as Error } from 'assets/icons/error.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { CSSObject } from '@mui/styled-engine';

export const alertStyles: {
  defaultProps?: ComponentsProps['MuiAlert'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiAlert'];
  variants?: ComponentsVariants['MuiAlert'];
} = {
  defaultProps: {
    components: {
      CloseIcon: Close,
    },
    iconMapping: {
      warning: <Warning />,
      error: <Error />,
      info: <Info />,
      success: <Success />,
    },
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      const styleMapping: Record<AlertColor, CSSObject> = {
        success: {
          backgroundColor: `var(--light-sucess-background, ${theme.palette.success.light})`,
          border: '1px solid' + theme.palette.success.main,
          boxShadow: '0px 6px 12px 0px rgba(0, 176, 7, 0.20)',
        },
        info: {
          backgroundColor: `var(--light-sucess-background, ${theme.palette.info.light})`,
          border: '1px solid' + theme.palette.info.main,
          boxShadow: '0px 6px 12px 0px rgba(57, 169, 255, 0.20)',
        },
        error: {
          backgroundColor: `var(--light-sucess-background, ${theme.palette.error.light})`,
          border: '1px solid' + theme.palette.error.main,
          boxShadow: '0px 6px 12px 0px rgba(228, 0, 56, 0.20)',
        },
        warning: {
          backgroundColor: `var(--light-sucess-background, ${theme.palette.warning.light})`,
          border: '1px solid' + theme.palette.warning.main,
          boxShadow: '0px 6px 12px 0px rgba(255, 175, 0, 0.20)',
        },
      };
      return {
        borderRadius: '4px',
        color: theme.palette.tech.toast,
        padding: '12px 20px',
        alignItems: 'flex-start',
        gap: '16px',
        alignSelf: 'stretch',

        ...theme.typography.button2,
        ...(ownerState.severity && styleMapping[ownerState.severity]),
      };
    },
  },
};
