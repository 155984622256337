import { FallbackProps } from 'react-error-boundary';
import styles from './ErrorFallback.module.scss';
import { useTranslation } from 'react-i18next';

export default function ErrorFallback({ resetErrorBoundary }: FallbackProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles.text}>{t('Произошла ошибка')}</p>

      <button className='button is-primary' onClick={resetErrorBoundary}>
        {t('Сбросить предохранитель')}
      </button>
    </div>
  );
}
