import { ReactElement, ReactNode } from 'react';
import FormControl from '@mui/material/FormControl';
import { Typography, TypographyOwnProps } from '@mui/material';
import Box from '@mui/material/Box';

export type WithLabelProps = {
  children?: ReactNode;
  id?: string;
  label?: string;
  errorText?: string;
  withoutLabel?: boolean;
  typographyProps?: TypographyOwnProps;
};

export type WithLabelComponent = (props: WithLabelProps) => ReactElement;

export const WithLabel: WithLabelComponent = ({
  label,
  children,
  id,
  errorText,
  withoutLabel,
  typographyProps,
}) => {
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {withoutLabel ? null : (
        <Typography
          id={id}
          variant={'button2'}
          mb={'4px'}
          mt={'20px'}
          display={'inline-block'}
          {...typographyProps}
        >
          {label}
        </Typography>
      )}
      <FormControl fullWidth variant='outlined'>
        {children}
      </FormControl>
      {errorText ? (
        <Box
          width={'100%'}
          left={0}
          position={'absolute'}
          bottom={'-21px'}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          <Typography variant={'caption'} color={'tech.hard'} noWrap>
            {errorText}
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};
