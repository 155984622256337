import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { createContext, ReactNode } from 'react';

export type ConfirmProps = {
  title?: string | ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  rejectsText?: string;
  description?: string;
  hideActionButtons?: boolean;
  isBlockedActions?: boolean;
  isBlockedConfirm?: boolean;
  isBlockedReject?: boolean;
  children?: ReactNode;
};

export type ErrorModalProps = Pick<
  ConfirmProps,
  'onConfirm' | 'onCancel' | 'description'
>;

export const ConfirmModalContent = ({
  title,
  onConfirm,
  onCancel,
  confirmText,
  rejectsText,
  description,
  hideActionButtons,
  isBlockedActions,
  isBlockedConfirm,
  isBlockedReject,
  children,
}: ConfirmProps) => {
  return (
    <Stack minWidth={500} p={'24px'} spacing={'24px'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        spacing={24}
        alignItems={'flex-start'}
      >
        {title && (
          <Typography variant={'h4'} color={'primaryBlue'}>
            {title}
          </Typography>
        )}
        <IconButton sx={{ p: 2 }} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {description && (
        <Typography variant={'body2'} color={'primaryBlue'}>
          {description}
        </Typography>
      )}
      {children}
      {!hideActionButtons && (
        <Grid container gap={'12px'} justifyContent={'flex-end'}>
          <Button
            disabled={isBlockedActions || isBlockedReject}
            variant={'contained'}
            color={'secondary'}
            onClick={onCancel}
          >
            {rejectsText ?? 'Отмена'}
          </Button>
          <Button
            disabled={isBlockedActions || isBlockedConfirm}
            variant={'contained'}
            color={'primary'}
            onClick={onConfirm}
          >
            {confirmText ?? 'Oк'}
          </Button>
        </Grid>
      )}
    </Stack>
  );
};

export const ErrorModalContent = ({
  onConfirm,
  onCancel,
  description,
}: ErrorModalProps) => {
  return (
    <ConfirmModalContent
      title={'Ошибка'}
      onConfirm={onConfirm}
      onCancel={onCancel}
      description={description}
      hideActionButtons
    />
  );
};

export const ErrorModalContext = createContext<
  ((options?: { description: string }) => Promise<unknown>) | null
>(null);
