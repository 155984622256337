import { SearchRequest } from 'core/models/search';
import { useEffect, useState } from 'react';
import { SortDirection } from 'core/models/search/search.interface';
import { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useSessionStorage } from 'react-use';
import { cloneDeep, isEqual } from 'lodash';

const initial = {
  offset: 0,
  limit: 10,
  filters: [],
  sort: [],
};

export function useRequest<
  Request extends Partial<SearchRequest> & { limit: number; offset: number }
>(id: string, defaultValue?: any, disableStorage?: boolean) {
  const [sessionRequest, setSessionRequest] = useSessionStorage<Request>(id);
  const [request, setRequest] = useState<Request>({
    ...cloneDeep(initial),
    ...defaultValue,
  });
  const [sortField, setSortField] = useState<any>();
  const [flag, setFlag] = useState<boolean>(false);

  const handleSetRequest = (val: Request) => {
    const o = Object.keys(val)
      // @ts-ignore
      .filter((k) => !!val[k])
      // @ts-ignore
      .reduce((a, k) => ({ ...a, [k]: val[k] }), {});
    if (!isEqual(request?.filters, val?.filters)) {
      // @ts-ignore
      o.offset = 0;
    }
    // @ts-ignore
    setRequest(o);
    // @ts-ignore
    setSessionRequest(o);
  };

  const handleSetFilter = (val: Request) => {
    const mod = { ...val };
    mod.offset = 0;
    handleSetRequest(mod);
  };

  useEffect(() => {
    if (!sortField) return;
    const mod = { ...request };
    const sort = mod.sort?.[0] || {};
    if (sort.key === sortField) {
      sort.direction =
        sort.direction === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC;
    } else {
      sort.key = sortField;
      sort.direction = SortDirection.ASC;
    }

    mod.sort ? (mod.sort[0] = sort) : (mod.sort = [sort]);
    handleSetRequest(mod);
    // eslint-disable-next-line
  }, [sortField, flag]);

  useEffect(() => {
    if (sessionRequest && !disableStorage) {
      handleSetRequest(sessionRequest);
    }
    // eslint-disable-next-line
  }, []);

  const handleSetSortField = (val: any) => {
    setSortField(val);
    setFlag((prev) => !prev);
  };

  const handleShowItemChange = (event: SelectChangeEvent) => {
    handleSetRequest({
      ...request,
      offset: 0,
      limit: event.target.value as unknown as number,
    });
  };

  const handleCountChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    handleSetRequest({
      ...request,
      offset: request.limit * (page - 1),
    });
  };

  const setDefault = () => {
    handleSetRequest({
      ...cloneDeep(initial),
      ...defaultValue,
    });
  };

  return {
    request,
    setRequest: handleSetFilter,
    limit: request.limit,
    page: request.offset ? request.offset / request.limit + 1 : 1,
    setSortField: handleSetSortField,
    handleShowItemChange,
    handleCountChange,
    handleCancelFilters: setDefault,
  };
}
