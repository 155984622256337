import { forwardRef, ForwardRefRenderFunction, useId } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { WithLabel } from '../WithLabel';
import {
  Chip,
  OutlinedInput,
  Select as MuiSelect,
  SelectProps,
  Typography,
} from '@mui/material';
import { isArray } from 'lodash';

export type Option = {
  name?: string;
  id?: string;
};

const SelectComponent: ForwardRefRenderFunction<
  HTMLInputElement,
  Omit<SelectProps<string>, 'onChange' | 'value'> & {
    value?:
      | ({
          name?: string;
          id?: string;
        } & Record<any, any>)
      | null;
    onChange: (value: { name: string; id: string }) => void;
    label?: string;
    errorText?: string;
    options: { name: string; id: string }[];
    readOnly?: boolean;
  }
> = function SelectComponent(
  {
    value,
    onChange,
    label,
    placeholder,
    options,
    readOnly,
    errorText,
    ...props
  },
  ref
) {
  const id = useId();
  return (
    <WithLabel label={label} id={id} errorText={errorText}>
      <FormControl fullWidth variant='outlined'>
        <MuiSelect
          error={!!errorText}
          readOnly={readOnly}
          labelId={id}
          value={value?.id ?? ''}
          onChange={(e) => {
            const fakeTarget: { name: string; id: string } = {
              id: e.target.value,
              name: options.find((o) => o.id === e.target.value)?.name || '',
            };
            onChange(fakeTarget);
          }}
          placeholder={placeholder}
          size='small'
          ref={ref}
          input={<OutlinedInput />}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography
                  lineHeight={'unset'}
                  variant={'body2'}
                  color={'tech.hard'}
                >
                  {placeholder}
                </Typography>
              );
            }

            if (isArray(selected))
              return selected.map((value) => (
                <Chip
                  sx={{
                    marginRight: '4px',
                    height: '24px',
                    borderRadius: '4px',
                    border: '1px solid  #E4E7EB',
                  }}
                  key={value}
                  label={value}
                />
              ));

            return options.find((o) => o.id === selected)?.name ?? '';
          }}
          {...props}
        >
          {options.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    </WithLabel>
  );
};

export const Select = forwardRef(SelectComponent);
