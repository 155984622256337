import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { AppPathOptions } from './AppPath.interface';
import Path from './Path';
import Protected from "./Protected";

export type { AppPathOptions };

export default class AppPath extends Path {
  protected readonly options: AppPathOptions;

  constructor(path: string, options: AppPathOptions) {
    super(path, options);
    this.options = options;
  }

  public asRoute = (forwardedProps?: RouteProps) => {
    const { Component, Layout, props: routeProps, can } = this.options;
    const props = {
      ...routeProps,
      ...forwardedProps,
    };
    const componentNode = <Component {...props} />;
    const element = Layout ? (
      <Layout {...props}>{componentNode}</Layout>
    ) : (
      componentNode
    );

    const protectedEl = can ? <Protected actions={can.actions} subject={can.subject}>{element}</Protected> : element;

    return <Route path={this.toPath()} element={protectedEl} key={element.key}/>;
  };
}
