import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  padding: '24px',
  borderRadius: '4px',
  p: 4,
  outline: 0,
  maxHeight: '90%',
  overflowY: 'auto',
};

interface ModalProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  children: React.ReactNode;
  onClose?: () => void;
}

export default function ModalComponent({
  open,
  setOpen,
  children,
  onClose,
}: ModalProps) {
  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
}
