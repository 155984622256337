import React, { Suspense } from 'react';
import {
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { Suspender } from 'components/indicators';
import { appPaths } from './paths';
import DefaultLayout from '../layout/DefaultLayout/DefaultLayout';
import ErrorPage from 'pages/error/ErrorPage';
import { PATHS } from './paths/appPaths';
import { useAuthContext } from '../auth';
import { FeatureFlagsModalContent } from 'components/featureFlags/FeatureFlagsWidget/FeatureFlagsWidget';

const history = createBrowserHistory({ window });

export const LocalizedSuspender = () => {
  const { t } = useTranslation();
  return <Suspender message={t('Загрузка...')} />;
};

const Error404PageWithLayout = (
  <DefaultLayout>
    <ErrorPage text={'Страница не найдена'} />
  </DefaultLayout>
);

const ErrorForbiddenPage = (
  <DefaultLayout>
    <ErrorPage
      text={
        'У вас отсутствует доступ к функционалу Лимитного модуля. Обратитесь к администратору'
      }
    />
  </DefaultLayout>
);

export default function Routing() {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Suspense fallback={LocalizedSuspender()}>
      <HistoryRouter history={history}>
        <Routes>
          {Object.values(appPaths.routes).map((route, key) => (
            <React.Fragment key={key}>{route.asRoute()}</React.Fragment>
          ))}
          <Route path={PATHS.FEATURES} element={<FeatureFlagsModalContent />} />
          <Route path={PATHS.FORBIDDEN} element={ErrorForbiddenPage} />
          <Route path={'*'} element={Error404PageWithLayout} />
        </Routes>
      </HistoryRouter>
    </Suspense>
  );
}
