import { QueryObserverOptions, useQuery } from 'react-query';
import axiosInstance from 'core/request/instance';
import { apiPaths } from 'core/routing/paths';
import { Feature, Setting } from './setting.interface';
import { SettingsOptionsResponse } from './settingsOptions.interface';

export function useSettings(options?: QueryObserverOptions<Array<Setting>>) {
  return useQuery<Array<Setting>, unknown>(
    ['settings'],
    () => {
      return axiosInstance.get(apiPaths.settings.toUrl());
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );
}

export function useSettingsOptions(
  options?: QueryObserverOptions<SettingsOptionsResponse>
) {
  return useQuery<SettingsOptionsResponse, unknown>(
    ['settings'],
    () => {
      return axiosInstance.get(apiPaths.settingsOptionsSearch().toUrl());
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );
}

export function useSettingByCode(
  code: string,
  options?: QueryObserverOptions<Setting>
) {
  return useQuery<Setting, unknown>(
    ['settings', code],
    () => {
      return axiosInstance.get(apiPaths.getSettingByCode(code).toUrl());
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );
}

export function useFeatures(options?: QueryObserverOptions<Feature[]>) {
  return useQuery<Feature[], unknown>(
    ['features'],
    () => {
      return axiosInstance.get(apiPaths.features.toUrl());
    },
    {
      keepPreviousData: true,
      ...options,
    }
  );
}
