export const features = {
  ContractTopV2: {
    description: 'Договоры v1.2',
    responsible: 'Arseniy rubtsov',
    enabled: false,
  },
  CreditSolutionItemExport: {
    description: 'Кредитное решение загрузка/выгрузка в exel',
    responsible: 'Arseniy rubtsov',
    enabled: true,
  }
};
