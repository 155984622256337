import { subject } from '@casl/ability';
import { ActionProps, SubjectProps } from './AbilityContext.interface';

export function extractActionAndSubject({
  as,
  I,
  do: doProp,
  of,
  a,
  an,
  this: thisProp,
  on,
}: ActionProps & SubjectProps) {
  const action = I || doProp;
  const subjectValue = of || a || an || thisProp || on;

  const shouldConvertToSubject =
    as !== undefined &&
    subjectValue !== null &&
    typeof subjectValue === 'object';

  const actualSubject = shouldConvertToSubject
    ? subject(as, subjectValue)
    : (subjectValue as string | undefined);

  return { action, subjectValue: actualSubject };
}
