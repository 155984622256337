import { useLocalStorage } from 'react-use';
import * as React from 'react';
import {
  FeatureFlagsProviderProperties,
  UseFeaturesContextReturnValue,
  FeatureFlagsProviderValue,
  UseFeatureProperties,
  Features,
} from './featureFlagsContext.interface';

const LOCAL_STORAGE_FEATURES_KEY = 'feature-flags';

const FeatureFlagsContext =
  React.createContext<FeatureFlagsProviderValue | null>(null);

function isKeysMatches<A extends object, B extends object>(
  firstObject: A,
  secondObject: B
) {
  const firstObjectKeys = Object.keys(firstObject);
  const secondObjectKeys = Object.keys(secondObject);

  const keysEqual = firstObjectKeys.every((featureKey) =>
    secondObjectKeys.includes(featureKey)
  );

  const keysLengthEqual = firstObjectKeys.length === secondObjectKeys.length;

  return keysEqual && keysLengthEqual;


}

export function FeatureFlagsProvider({
  initialFeatures,
  children,
}: FeatureFlagsProviderProperties) {
  const [localStorageFeatures, setLocalStorageFeatures] =
    useLocalStorage<Features>(LOCAL_STORAGE_FEATURES_KEY, initialFeatures);

  function getValue() {
    // Фикс тайпингов хука useLocalStorage - https://github.com/streamich/react-use/issues/1483
    const features = localStorageFeatures as Features;

    // Фикс тайпингов хука useLocalStorage - https://github.com/streamich/react-use/issues/1483
    const setFeatures = setLocalStorageFeatures as React.Dispatch<
      React.SetStateAction<Features>
    >;

    return {
      features,
      initialFeatures,
      setFeatures,
    };
  }

  if (
    isKeysMatches(initialFeatures, localStorageFeatures as Features) === false
  ) {
    setLocalStorageFeatures(initialFeatures);
  }

  return (
    <FeatureFlagsContext.Provider value={getValue()}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeaturesContext(): UseFeaturesContextReturnValue {
  const context = React.useContext(FeatureFlagsContext);

  if (context === null) {
    throw new Error('Хук useFeature используется за пределами контекста.');
  }

  return context;
}

export function useFeature({ featureName }: UseFeatureProperties): boolean {
  const context = useFeaturesContext();
  const feature = context.features[featureName];
  return feature.enabled;
}
