import {AxiosRequestConfig} from 'axios';
import AbstractAuthClient, {
    AbstractAuthClientInitOptions,
} from '../AbstractAuthClient/AbstractAuthClient.interface';
import {User, UserManager, UserManagerSettings} from "oidc-client-ts";
import axiosInstance from "core/request/instance";
import {apiPaths} from "core/routing";

export default class Oath2Client<RawClaims>
    implements AbstractAuthClient<RawClaims> {
    private readonly userManager: UserManager;

    constructor(
        settings: UserManagerSettings,
    ) {
        this.userManager = new UserManager(settings);
    }

    getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    renewToken(): Promise<User | null> {
        return this.userManager.signinSilent();
    }

    logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

    updateApi(requestConfig: AxiosRequestConfig, user: User | null): void {
        if (!requestConfig.headers) {
            requestConfig.headers = {};
        }
        requestConfig.headers.Authorization = `Bearer ${user?.access_token}`;
    }

    async init({onClaimsUpdate}: AbstractAuthClientInitOptions<RawClaims>) {
        this.getUser().then((user: User | null) => {
            if (!user) {
                this.sign(onClaimsUpdate);
            } else {
                this.updatePermission(onClaimsUpdate);
            }
        })

        return true;
    }

    async sign(onClaimsUpdate: any) {
        const authFunc = window.location.href.includes("state=")
            ? () => this.userManager.signinRedirectCallback()
            : () => this.userManager.signinRedirect();
        authFunc().then((res) => {
            console.log(res)
            this.updatePermission(onClaimsUpdate);
        }).catch(function (err) {
            console.error(err)
        });
    }

    async updatePermission(onClaimsUpdate: any) {
        const username = await this.userInfo();
        axiosInstance.get(apiPaths.permissions.toUrl())
            .then((data: any) => onClaimsUpdate({permissions: [...data], username} as RawClaims))
            .catch(() => onClaimsUpdate({permissions: [], username} as RawClaims));
    }

    async ensureAuthentication(requestConfig
                                   :
                                   AxiosRequestConfig
    ) {
        try {
            // @ts-ignore
            await this.getUser().then((user: User) => {
                if (user && user.access_token) {
                    this.updateApi(requestConfig, user);
                } else if (user) {
                    // @ts-ignore
                    return this.renewToken().then((user: User) => {
                        this.updateApi(requestConfig, user);
                    });
                } else {
                    throw new Error('user is not logged in');
                }
            });
            return true;
        } catch (error) {
            console.error({error})
            return false;
        }
    }

    async restoreAuthentication() {
        try {
            await this.renewToken();
            return true;
        } catch (error) {
            await this.logout();
            return false;
        }
    }

    async userInfo() {
        const user = await this.getUser();
        return user?.profile?.preferred_username || '';
    }
}
