import Menu from './components/menu/Menu';
import logo from 'assets/icons/slogan.svg';
import user from 'assets/icons/user.svg';
import { useAuthContext } from 'core/auth';
import { useScrollToAnchor } from '../../../routing/ScrollToAnchor/useScrollToAnchor';

export default function Sidebar() {
  const { claims } = useAuthContext();
  useScrollToAnchor();

  return (
    <div
      className='main'
      style={{
        padding: '10px 16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <img src={logo} alt='logo' />
      <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
        <img src={user} alt='' />
        <span style={{ fontWeight: '600', fontSize: '16px' }}>
          {claims?.username}
        </span>
      </div>
      <div style={{ background: '#E4E7EB', height: '1px' }} />
      <Menu />
    </div>
  );
}
