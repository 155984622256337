import { ComponentsProps } from '@mui/material/styles/props';
import { ComponentsOverrides } from '@mui/material/styles/overrides';
import { Theme } from '@mui/material';
import { ComponentsVariants } from '@mui/material/styles/variants';

export const buttonStyles: {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
} = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      fontWeight: 600,
      textTransform: 'none',
      boxShadow: 'none',
      backgroundColor: theme.palette.red.hard,
      '& path': {
        transition: 'fill 250ms cubic-bezier(0.4, 0, 0.2, 1)',
      },
      '&:hover': {
        backgroundColor: theme.palette.red.hover,
        color: 'white',
      },
      '&:disabled': {
        backgroundColor: theme.palette.tech.light,
        color: 'white',
      },
      ...(ownerState.size === 'small' && {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '8px 24px',
        letterSpacing: '-0.28px',
      }),
      ...(ownerState.size === 'medium' && {
        padding: '10px 24px',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.28px',
      }),
      ...(ownerState.variant === 'text' && {
        padding: '1px 0',
        '& MuiButton-endIcon': {
          marginLeft: '12px',
          marginRight: 0,
        },
      }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'primary' && {
          backgroundColor: theme.palette.red.hard,
          color: theme.palette.tech.white,
          '&:hover': {
            backgroundColor: theme.palette.red.hover,
          },
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'secondary' && {
          backgroundColor: theme.palette.tech.light,
          color: theme.palette.primaryBlue,
          '&:hover': {
            backgroundColor: theme.palette.primaryBlue,
            color: theme.palette.tech.white,
          },
        }),
      ...(ownerState.variant === 'contained' &&
        ownerState.color === 'success' && {
          backgroundColor: theme.palette.success.dark,
          color: theme.palette.tech.white,
        }),
      ...(ownerState.variant === 'text' &&
        ownerState.color === 'primary' && {
          backgroundColor: theme.palette.tech.white,
          color: theme.palette.red.hard,
          '&:hover': {
            color: theme.palette.red.hover,
            backgroundColor: theme.palette.tech.white,
            '& path': {
              fill: theme.palette.red.hover,
            },
          },
          '&:disabled': {
            backgroundColor: theme.palette.tech.white,
            color: theme.palette.tech.light,
            '& path': {
              fill: theme.palette.tech.light,
            },
          },
        }),
      ...(ownerState.variant === 'text' &&
        ownerState.color === 'secondary' && {
          backgroundColor: theme.palette.tech.white,
          color: theme.palette.primaryBlue,
          '&:hover': {
            backgroundColor: theme.palette.tech.white,
          },
          '&:disabled': {
            backgroundColor: theme.palette.tech.white,
            color: theme.palette.primaryBlue,
          },
        }),
      ...(ownerState.variant === 'outlined' &&
        ownerState.color === 'primary' && {
          backgroundColor: theme.palette.tech.white,
          color: theme.palette.red.hard,
          border: `1px solid ${theme.palette.red.hard}`,
          '&:hover': {
            color: theme.palette.red.hover,
            border: `1px solid ${theme.palette.red.hover}`,
          },
        }),
    }),
  },
};
