import { Context, ReactNode } from 'react';
import { AnyAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { AnyCanProps } from './AbilityContext.interface';
import { extractActionAndSubject } from './utils';

export default function createCanComponent<
  AbilityContextValue extends AnyAbility
>({ AbilityContext }: { AbilityContext: Context<AbilityContextValue> }) {
  const ConnectedCan = createContextualCan(AbilityContext.Consumer);

  function Can({
    children,
    as,
    not,
    ...actionAndSubjectProps
  }: { children: ReactNode } & AnyCanProps) {
    const { action, subjectValue } = extractActionAndSubject(
      actionAndSubjectProps
    );

    if (!action || !subjectValue) {
      // Если не удается определит правило, то запрещаем его
      return null;
    }

    return (
      // @ts-ignore
      <ConnectedCan do={action} on={subjectValue}>
        {children}
      </ConnectedCan>
    );
  }

  Can.defaultProps = {
    not: false,
  };

  return Can;
}
