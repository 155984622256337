import { AxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';
import AbstractAuthClient, {
  AbstractAuthClientInitOptions,
} from '../AbstractAuthClient/AbstractAuthClient.interface';
import axiosInstance from "core/request/instance";
import {apiPaths} from "core/routing";

export * from './KeycloakClient.interface';

export default class KeycloakClient<RawClaims>
  implements AbstractAuthClient<RawClaims>
{
  private readonly keycloak: Keycloak.KeycloakInstance;
  private readonly initOptions: Keycloak.KeycloakInitOptions;

  constructor(
    config: Keycloak.KeycloakConfig,
    initOptions: Keycloak.KeycloakInitOptions
  ) {
    this.keycloak = new Keycloak(config);
    this.initOptions = initOptions;
  }

  init({ onClaimsUpdate }: AbstractAuthClientInitOptions<RawClaims>) {
    const keycloak = this.keycloak;

    keycloak.onAuthSuccess = handleSuccess;
    keycloak.onAuthRefreshSuccess = handleSuccess;

    keycloak.onAuthError = handleError;
    keycloak.onAuthRefreshError = handleError;

    keycloak.onTokenExpired = handleTokenExpired;

    return keycloak.init(this.initOptions);

    async function handleSuccess() {
      if (keycloak.tokenParsed) {
        const username = keycloak?.tokenParsed?.name
        await axiosInstance.get(apiPaths.permissions.toUrl())
            .then((data: any) => onClaimsUpdate({permissions: [...data], username} as RawClaims))
            .catch(() => onClaimsUpdate({permissions: [], username} as RawClaims));
      } else {
        handleError();
      }
    }

    function handleError() {
      keycloak.logout();
      onClaimsUpdate(null as RawClaims);
    }

    function handleTokenExpired() {
      keycloak.updateToken(-1);
    }
  }

  async ensureAuthentication(requestConfig: AxiosRequestConfig) {
    try {
      await this.keycloak.updateToken(5);
      // eslint-disable-next-line no-param-reassign
      if (!requestConfig.headers) {
        requestConfig.headers = {};
      }
      requestConfig.headers.Authorization = `Bearer ${this.keycloak.token}`;
      return true;
    } catch (error) {
      return false;
    }
  }

  async restoreAuthentication() {
    try {
      await this.keycloak.updateToken(-1);
      return true;
    } catch (error) {
      this.keycloak.logout();
      return false;
    }
  }

}
