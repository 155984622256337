import { createAbilityContext } from 'components/auth';
import useAbility from './useAbility';
import { AppAbility } from './abilities.interface';
import claimsToAbility from './claimsToAbility';

const { AbilityProvider, useCannot, useCan, Can, useCanOr } =
  createAbilityContext<AppAbility>({
    useAbility,
    defaultAbility: claimsToAbility(null),
  });

export { AbilityProvider, useCannot, useCan, Can, useCanOr };
