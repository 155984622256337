import Path from '../../utils/Path';

const api = Path.preset({ basename: '' });

const DOC_CONTRACTS_PATH = '/limm-control/contracts/doc-contracts';

export const docContracts = api(DOC_CONTRACTS_PATH);

export const contractAdditionalContractsSearch = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/additional-contracts/search`
  );

export const contractDealsSearch = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/deal-contracts/search`
  );

export const getContract = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}`);

export const closeContract = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/close`);

export const getContractSpec = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/specs`);

export const getContractDetails = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/details`);

export const pledges = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/pledges`);

export const contractAdditionalContractsStatements = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/additional-contracts/statements`
  );

export const contractAdditionalContract = (
  contractId?: string,
  additionalContractId?: string
) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/additional-contracts/${additionalContractId}`
  );

export const contractAdditionalContractCreate = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/additional-contracts`
  );

export const contractDealsStatements = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/deal-contracts/statements`
  );

export const contractsStatements = api(
  `/limm-control/contracts/doc-contracts/statements`
);

export const contractAdditionalStatements = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/additional-contracts/statements`
  );

export const contractRepaymentsStatements = (contractId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/repayments/statements`
  );

export const contractPledge = (contractId?: string) =>
  api(`/limm-control/contracts/deal-contracts/${contractId}/pledges`);

export const getContractPledge = (contractId?: string, pledgeId?: string) =>
  api(
    `/limm-control/contracts/doc-contracts/${contractId}/pledges/${pledgeId}`
  );

export const contractRepayment = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/repayment`);

export const contractLimitRecalculate = (contractId?: string) =>
  api(`/limm-control/contracts/doc-contracts/${contractId}/recalculate`);
